import React from 'react';
import Seo from 'modules/app/components/Seo';
import { TalentsPage } from 'modules/talents';
import { CookieConsent, PublicLayout } from '../modules/app';
import useSiteMetadata from 'modules/app/graphql/useSiteMetadata';

const Talents: React.FC<WebPageProps> = ({ pageContext }) => {
  const { siteUrl } = useSiteMetadata();
  const { intl } = pageContext;
  const path = `${siteUrl}/${intl.language}${intl.originalPath}`;

  return (
    <>
      <Seo
        url={path}
        title="Talents - Roango"
        description="Control your privacy; choose the jobs you want to apply for, the information you want to share, and whom you want to share it with."
        image={`${siteUrl}/roango-share-image.png`}
      />

      <PublicLayout>
        <TalentsPage />
      </PublicLayout>

      <CookieConsent />
    </>
  );
};

export default Talents;
